<template>
  <div class="object-viewer-container">
    <!-- Top Bar -->
    <div class="top-bar">
      <button class="back-button" @click="goBack">
        <img src="@/assets/icons/chevron-left.svg" alt="Back" />
      </button>
      <img src="@/assets/logotype/5.png" alt="OneShot Logo" class="logo" />
      <div class="spacer"></div> <!-- To balance the back button -->
    </div>
    <ModelViewerWrapper v-if="object" :object="object" />
    <div class="description-text" v-if="object">
      Someone shared with you this 3D model. It was created in seconds using Oneshot.
      You can use this model anywhere. If you wish to create 3D quickly, add this model
      to your OneShot, and start capturing now!
    </div>
    <div class="action-buttons" v-if="object">
      <button class="action-button download-button" @click="downloadModel">
        <img src="@/assets/icons/download-icon.svg" alt="Download" />
        <span class="button-label">Download</span>
      </button>
      <button class="action-button add-button" @click="addToOneShot">
        <img src="@/assets/icons/add-icon.svg" alt="Add to OneShot" />
        <span class="button-label">Add to OneShot</span>
      </button>
    </div>
  </div>
</template>

<script>
import ModelViewerWrapper from '@/components/ModelViewerWrapper.vue';
import { addObject } from '../indexedDb';

export default {
  name: 'PublicObjectViewer',
  components: {
    ModelViewerWrapper,
  },
  data() {
    return {
      clientId: this.getClientId(),
      object: null,
      analytics: window.analytics, // Assuming Segment is initialized globally
    };
  },
  async mounted() {
    const modelId = this.$route.params.modelId;
    if (!modelId) {
      this.$router.push('/');
      return;
    }
    // Analytics tracking
    this.analytics.identify(this.clientId, {
      deviceType: this.deviceType(),
      userAgent: navigator.userAgent,
    });

    const baseUrl = 'https://oneshot.blob.core.windows.net/public';
    this.object = {
      id: modelId.split('_')[1] || modelId,
      glb_url: `${baseUrl}/${modelId}.glb`,
      usdz_url: `${baseUrl}/${modelId}.usdz`,
      poster: `${baseUrl}/${modelId}.png`,
    };

    this.analytics.page('Public Object Viewer', {
      objectId: this.object.id,
    });
    const urlExists = await this.checkUrlExists(this.object.glb_url);
    if (!urlExists) {
      alert('The requested model does not exist.');
      this.analytics.track('Model Not Found', {
        objectId: this.object.id,
        objectUrl: this.object.glb_url,
      });
      this.$router.push('/');
      return;
    }
    this.analytics.track('Object Viewed', {
      objectId: this.object.id,
      objectUrl: this.object.glb_url,
    });
  },
  methods: {
    async checkUrlExists(url) {
      try {
        const response = await fetch(url, { method: 'HEAD' });
        return response.ok;
      } catch (error) {
        return false;
      }
    },
    downloadModel() {
      const link = document.createElement('a');
      link.href = this.object.glb_url;
      link.download = `model-${this.object.id}.glb`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      this.analytics.track('Model Downloaded', {
        objectId: this.object.id,
        objectUrl: this.object.glb_url,
      });
    },
    async addToOneShot() {
      const newObject = {
        id: Date.now(),
        thumbnail: await this.getBase64(this.object.poster),
        label: 'Completed',
        progress: 100,
        timestamp: new Date().toISOString(),
        localUrl: null,
        url: this.object.glb_url,
        glb_url: this.object.glb_url,
        usdz_url: this.object.usdz_url,
        poster: this.object.poster,
      };
      console.log('Adding to OneShot:', newObject);
      await addObject(newObject);

      // Send analytics event
      this.analytics.track('Add to OneShot', {
        objectId: newObject.id,
        objectUrl: newObject.url,
      });

      // Redirect to MainView
      this.$router.push('/');
    },
    getClientId() {
      let clientId = localStorage.getItem('client_id');
      if (!clientId) {
        clientId = Math.random().toString(36).substring(2, 11);
        localStorage.setItem('client_id', clientId);
      }
      return clientId;
    },
    getBase64(url) {
      return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.onload = function() {
          const reader = new FileReader();
          reader.onloadend = function() {
            resolve(reader.result);
          };
          reader.readAsDataURL(xhr.response);
        };
        xhr.onerror = function() {
          reject(new Error('Failed to convert image to base64'));
        };
        xhr.open('GET', url);
        xhr.responseType = 'blob';
        xhr.send();
      });
    },
    deviceType() {
      if (/iPhone|iPad|iPod/.test(navigator.userAgent)) {
        return 'iOS';
      } else if (/Mac OS X(?!.*iPhone)/.test(navigator.userAgent)) {
        return 'MacOS';
      } else if (navigator.userAgent.includes('Android')) {
        return 'Android';
      } else {
        return 'Others';
      }
    },
    goBack() {
      this.$router.push('/');
      this.analytics.track('Navigated Back to MainView', {
        objectId: this.object.id,
        clientId: this.clientId,
      });
    },
  },
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow: hidden; /* Disable scrolling */
  font-family: 'Montserrat', sans-serif; /* Change the font */
  background-color: #fff; /* Change the background color */
}

.object-viewer-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100svw;
  height: 100svh;
  background: #fff;
  position: relative;
}

/* Top Bar Styles */
.top-bar {
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 0px 20px;
  background-color: #fff;
  justify-content: center;
  z-index: 1000;
}

.back-button {
  background: none;
  border: none;
  transition: color 0.3s ease;
  padding: 20px;
}

.back-button img {
  width: 30px;
  height: 30px;
}

.back-button:hover {
  color: #1e2582;
}

.logo {
  display: inline-block;
  margin: 0 auto;
  height: 45px;
}

.spacer {
  width: 70px; /* Same width as back-button to balance the layout */
}

/* Action Buttons Container */
.action-buttons {
  display: flex;
  gap: 20px;
  margin: 20px 8px;
}

/* Individual Action Button */
.action-button {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 10px 20px;
  background-color: #fff;
  height: 60px;
  width: 180px;
  border: 2px solid;
  border-radius: 13px;
  color: #000;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.action-button:hover {
  opacity: 0.9;
}

/* Download Button Styles */
.download-button {
  border-color: #252D97;
}

.download-button img {
  filter: brightness(0) saturate(100%) invert(26%) sepia(10%) saturate(7075%) hue-rotate(209deg) brightness(89%) contrast(117%);
}

.download-button .button-label {
  color: #252D97;
}

.download-button:active {
  background-color: #252D97;
  transform: scale(0.98);
}

.download-button:active img {
  filter: invert(100%);
}

.download-button:active .button-label {
  color: #fff;
}

/* Add Button Styles */
.add-button {
  border-color: #28a745;
}

.add-button img {
  filter: brightness(0) saturate(100%) invert(48%) sepia(70%) saturate(320%) hue-rotate(85deg) brightness(90%) contrast(89%);
}

.add-button .button-label {
  color: #28a745;
}

.add-button:active {
  background-color: #28a745;
  transform: scale(0.98);
}

.add-button:active img {
  filter: invert(100%);
}

.add-button:active .button-label {
  color: #fff;
}

.button-label {
  display: inline-block;
  margin: 0 auto;
}

.action-button img {
  width: 20px;
  height: 20px;
}


/* Descriptive Text Styles */
.description-text {
  max-width: 600px;
  text-align: center;
  margin-top: 20px;
  padding: 0 20px;
  font-size: 16px;
  color: #333;
  line-height: 1.5;
}

/* Responsive Adjustments */
@media (max-width: 400px) {
  .action-button {
    width: 140px;
    font-size: 16px;
  }

  .description-text {
    font-size: 14px;
  }
}
</style>