<template>
  <div class="thumbnail" :class="{ completed: object.progress === 100 }" @pointerdown="handlePointerDown" @contextmenu="handleContextMenu">
    <template v-if="object.url">
      <img :src="object.thumbnail" draggable="false" />
    </template>
    <template v-else>
      <div>
        <img :src="object.thumbnail" draggable="false" />
        <div class="progress-overlay" v-if="object.progress < 100" :style="{ fontSize: object.progress === 0 ? '0.8em' : '1em' }">
          {{ object.progress === 0 ? 'Queued' : object.progress + '%' }}
        </div>
      </div>
    </template>
    <div class="progress-bar" :style="{ width: object.progress + '%', backgroundColor: getColor(object.progress) }"></div>
  </div>
  <div ref="customMenu" class="custom-menu">
    <ul>
      <li @click.stop="viewModel">View</li>
      <li @click.stop="confirmDelete">Delete</li>
      <li @click.stop="shareModel">Share</li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'ThumbnailView',
  props: {
    object: Object
  },
  emits: ['delete', 'view-model'],
  data() {
    return {
      showModelViewer: false,
      longPressDetected: false,
    };
  },
  methods: {
    isIOS() {
      const a = document.createElement("a");
      return a.relList.supports("ar");
      // return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    },
    isAndroid() {
      return /Android/.test(navigator.userAgent);
    },
    getAndroidARLink(modelUrl) {
      return `intent://arvr.google.com/scene-viewer/1.0?file=${modelUrl}#Intent;scheme=https;package=com.google.android.googlequicksearchbox;action=android.intent.action.VIEW;S.browser_fallback_url=https://nuvo3d.com;end;`;
    },
    getLink(localUrl, url) {
      if(this.isIOS()) {
        if(localUrl) {
          return `${localUrl}#canonicalWebPageURL=${url}`;
        } else {
          return `${url}#canonicalWebPageURL=${url}`;
        }
      } else if (this.isAndroid()) {
        return this.getAndroidARLink(url);
      } else {
        return url;
      }
    },
    getColor(progress) {
      const red = Math.floor(128 * (1 - progress / 100));
      const green = Math.floor(128 * (progress / 100));
      return `rgb(${red},${green},0)`;
    },
    handlePointerDown(event) {
      event.preventDefault(); // Prevents default drag behavior
      const longPressDuration = 500; // Duration in ms for a long press
      this.longPressDetected = false;

      this.longPressTimeout = setTimeout(() => {
        this.longPressDetected = true;
        this.showCustomMenu(event.clientX, event.clientY);
      }, longPressDuration);

      const removeTimeout = () => clearTimeout(this.longPressTimeout);

      const handlePointerUp = () => {
        if (!this.longPressDetected) {
          this.viewModel();
        }
        removeTimeout();
      };

      event.target.addEventListener('pointerup', handlePointerUp, { once: true });
      event.target.addEventListener('pointermove', removeTimeout, { once: true });
      event.target.addEventListener('pointercancel', removeTimeout, { once: true });
    },
    handleContextMenu(event) {
      event.preventDefault(); // Prevent the default context menu
      this.showCustomMenu(event.clientX, event.clientY); // Show the custom context menu
    },
    showCustomMenu(x, y) {
      const customMenu = this.$refs.customMenu;

      customMenu.style.left = `${x}px`;
      customMenu.style.top = `${y}px`;
      customMenu.style.display = 'block';
      customMenu.style.zIndex = '1000';

      document.addEventListener('click', this.hideCustomMenu, { once: true });
    },
    hideCustomMenu() {
      const customMenu = this.$refs.customMenu;
      customMenu.style.display = 'none';
    },
    viewModel() {
      this.hideCustomMenu();
      if (this.object.progress === 100) {
        this.$emit('view-model', this.object);
      }
    },
    closeModelViewer() {
      this.showModelViewer = false;
    },
    confirmDelete() {
      this.hideCustomMenu();
      if (confirm('Are you sure you want to delete this item?')) {
        this.deleteModel();
      }
    },
    deleteModel() {
      this.$emit('delete', this.object.id);
    },
    shareModel() {
      this.hideCustomMenu();
      // Trigger the share functionality
      this.analytics.track('Object Shared', { 
        objectId: this.object.id,
      });
      if (navigator.share) {
        navigator.share({
          title: 'Check out this 3D model',
          url: this.object.url,
        }).catch(error => {
          console.error('Error sharing:', error);
        });
      } else {
        alert('Sharing not supported in this browser');
      }
    }
  }
};
</script>

<style scoped>
.thumbnail {
  scroll-snap-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  border-radius: 8px;
  transition: transform 0.3s ease;
  position: relative; /* To position the overlay correctly */
  touch-action: pan-x; /* Allows horizontal panning only */
  user-select: none; /* Prevents text selection */
}

.thumbnail img {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 8px;
}

.progress-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  /* Add the following styles to make the text fit its container */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.progress-bar {
  width: 100%;
  min-width: 8px;
  height: 8px;
  margin-top: 2px;
  border-radius: 4px;
  transition: width 0.3s ease, background-color 0.3s ease;
  align-self: flex-start;
}

.custom-menu {
  display: none;
  position: fixed;
  background-color: white;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
  z-index: 1000;
  border-radius: 5px;
  overflow: hidden;
  width: 150px;
}

.custom-menu ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.custom-menu li {
  padding: 10px;
  cursor: pointer;
}

.custom-menu li:hover {
  background-color: #f0f0f0;
}

.model-viewer-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.model-viewer-container {
  position: relative;
  width: 90vw;
  height: 90vh;
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.5em;
  cursor: pointer;
  z-index: 1001;
}
</style>