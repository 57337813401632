<template>
  <div class="model-list-view">
    <div class="top-bar">
      <span class="title">All Models</span>
      <button class="close-button" @click="$emit('close')">✖️</button>
    </div>
    <div class="content scrollable">
      <div class="list-item" v-for="object in objects" :key="object.id">
        <img :src="object.thumbnail" class="thumbnail" @click="openModel(object)" />
        <span class="label" @click="openModel(object)">{{ object.label }}</span>
        <button @click="confirmDelete(object.id)" class="trash-button">🗑️</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModelListView',
  props: {
    objects: Array
  },
  emits: ['close', 'delete', 'view-model'],
  methods: {
    confirmDelete(id) {
      if (confirm('Are you sure you want to delete this item?')) {
        this.$emit('delete', id);
      }
    },
    openModel(object) {
      if (object.progress === 100) {
        this.$emit('view-model', object);
      }
    }
  },
  mounted() {
    this.analytics.track('Model List View Opened');
  }
};
</script>

<style scoped>
.model-list-view {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: white;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  max-height: 80%;
  min-height: 400px;
  display: flex;
  flex-direction: column;
}

.top-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #eee;
  background: white;
}

.close-button {
  background: none; /* Remove background */
  border: none; /* Remove border */
  font-size: 1.5em; /* Adjust font size for emoji */
  padding-inline: 10px;
  cursor: pointer;
  margin-left: auto;
}

.title {
  flex: 1;
  flex-grow: 1;
  text-align: center;
  font-size: 1.2em;
  font-weight: bold;
}

.content {
  overflow-y: auto;
  flex: 1;
}

.list-item {
  display: flex;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #eee;
}

.thumbnail {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 8px;
  margin-right: 10px;
}

.label {
  flex: 1;
}

.trash-button {
  background: none;
  border: none;
  color: red;
  cursor: pointer;
}
</style>
