<template>
  <div class="model-viewer-wrapper">
    <model-viewer
      :src="object.glb_url"
      :ios-src="object.usdz_url ? object.usdz_url : undefined"
      :poster="object.poster"
      alt="3D Model"
      shadow-intensity="1"
      camera-controls
      auto-rotate
      auto-rotate-delay="0"
      rotation-per-second="45"
      ar
      ar-modes="webxr scene-viewer quick-look"
      ar-scale="auto"
      style="width: 100%; height: 100%;"
      @progress="onProgress"
    >
      <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
      <div slot="progress-bar" class="custom-progress-bar" :class="{ loading: isLoading }" >
        <svg class="progress-circle" viewBox="0 0 100 100">
          <!-- Background Circle -->
          <circle
            class="progress-circle-bg"
            cx="50"
            cy="50"
            r="45"
            stroke-width="10"
            fill="none"
          />
          <!-- Progress Indicator -->
          <circle
            class="progress-circle-bar"
            cx="50"
            cy="50"
            r="45"
            stroke-width="10"
            fill="none"
            :stroke-dasharray="circumference"
            :stroke-dashoffset="strokeDashoffset"
          />
        </svg>
      </div>
    </model-viewer>
  </div>
</template>

<script>
export default {
  name: 'ModelViewerWrapper',
  props: {
    object: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isLoading: true,
      progress: 0,
    };
  },
  computed: {
    circumference() {
      return 2 * Math.PI * 45;
    },
    strokeDashoffset() {
      return this.circumference * (1 - this.progress);
    },
  },
  methods: {
    onProgress(event) {
      this.progress = event.detail.totalProgress;
      this.isLoading = this.progress < 1;
    },
  },
};
</script>

<style scoped>
.model-viewer-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

/* Custom Progress Bar Styles */
.custom-progress-bar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* Center the spinner */
  display: flex;
  justify-content: center;
  align-items: center;
  /* Initially hidden */
  opacity: 0;
  transition: opacity 0.3s ease;
  pointer-events: none;
}

.custom-progress-bar.loading {
  opacity: 1;
}

/* Progress Circle Styles */
.progress-circle {
  width: 60px;
  height: 60px;
  transform: rotate(-90deg);
  background: rgba(211, 211, 211, 0.5);
  border-radius: 10px;
  padding: 20px;
}

.progress-circle-bg {
  stroke: rgba(255, 255, 255, 0.3);
}

.progress-circle-bar {
  stroke: white;
  stroke-linecap: round;
  transition: stroke-dashoffset 0.3s ease;
}

/* Fade the poster image when loading */
model-viewer.loading[poster]::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: white;
  opacity: 0.5;
  transition: opacity 0.3s ease;
  pointer-events: none;
}
</style>