<template>
  <transition name="drawer-transition">
    <div v-if="visible" class="drawer-overlay" @touchmove.prevent>
      <div
        class="drawer"
        :style="{ transform: `translateY(${translateY}px)` }"
        @touchstart="onTouchStart"
        @touchmove="onTouchMove"
        @touchend="onTouchEnd"
        @mousedown="onMouseDown"
      >
        <div
          class="drawer-header"
          @touchstart="onHeaderTouchStart"
          @touchmove="onHeaderTouchMove"
          @touchend="onHeaderTouchEnd"
          @mousedown="onHeaderMouseDown"
        >
          <div class="drawer-handle"></div>
          <button class="close-button" @click="closeDrawer">✖️</button>
        </div>
        <div class="drawer-content">
          <slot></slot>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'DrawerView',
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    initialHeight: {
      type: Number,
      default: 0, // Start from the bottom
    },
  },
  emits: ['update:visible'],
  data() {
    return {
      startY: 0,
      currentY: 0,
      translateY: 0,
      isDragging: false,
      windowHeight: window.innerHeight,
    };
  },
  methods: {
    onTouchStart(event) {
      // Prevent touch events on the drawer body from affecting the drawer position
      if (!this.isDragging) {
        event.stopPropagation();
      }
    },
    onTouchMove(event) {
      if (!this.isDragging) {
        event.stopPropagation();
      }
    },
    onTouchEnd(event) {
      if (!this.isDragging) {
        event.stopPropagation();
      }
    },
    onHeaderTouchStart(event) {
      this.isDragging = true;
      this.startY = event.touches[0].clientY;
      this.translateY = 0;
    },
    onHeaderTouchMove(event) {
      if (!this.isDragging) return;
      this.currentY = event.touches[0].clientY;
      const deltaY = this.currentY - this.startY;
      if (deltaY > 0) {
        this.translateY = deltaY;
      }
    },
    onHeaderTouchEnd() {
      if (!this.isDragging) return;
      this.isDragging = false;
      // If dragged more than 50px, close the drawer
      if (this.translateY > 50) {
        this.closeDrawer();
      } else {
        // Snap back to the top
        this.translateY = 0;
      }
    },
    onMouseDown(event) {
      // Prevent mouse events on the drawer body from affecting the drawer position
      if (!this.isDragging) {
        event.stopPropagation();
      }
    },
    onHeaderMouseDown(event) {
      // For desktop support
      this.isDragging = true;
      this.startY = event.clientY;
      this.translateY = 0;

      document.addEventListener('mousemove', this.onHeaderMouseMove);
      document.addEventListener('mouseup', this.onHeaderMouseUp);
    },
    onHeaderMouseMove(event) {
      if (!this.isDragging) return;
      this.currentY = event.clientY;
      const deltaY = this.currentY - this.startY;
      if (deltaY > 0) {
        this.translateY = deltaY;
      }
    },
    onHeaderMouseUp() {
      if (!this.isDragging) return;
      this.isDragging = false;
      document.removeEventListener('mousemove', this.onHeaderMouseMove);
      document.removeEventListener('mouseup', this.onHeaderMouseUp);
      // If dragged more than 50px, close the drawer
      if (this.translateY > 50) {
        this.closeDrawer();
      } else {
        // Snap back to the top
        this.translateY = 0;
      }
    },
    closeDrawer() {
      this.translateY = this.windowHeight;
      this.$emit('update:visible', false);
      this.translateY = 0; // Reset translateY after closing
    },
    watch: {
      visible(newValue) {
        if (newValue) {
          document.body.style.overflow = 'hidden';
          this.translateY = 0; // Reset translateY when drawer is opened
        } else {
          document.body.style.overflow = '';
        }
      },
    },
  },
  mounted() {
    // Lock body scroll when the drawer is open
    document.body.style.overflow = 'hidden';
  },
};
</script>

<style scoped>
.drawer-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* Optional: Add a semi-transparent background */
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.drawer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 95svh; /* Adjust as needed */
  background: #fff;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  overflow: hidden;
  touch-action: none;
  transform: translateY(0);
  transition: transform 0.2s ease-out;
}

.drawer-header {
  height: 40px;
  background: #f0f0f0;
  display: flex;
  justify-content: center;
  align-items: center;
  touch-action: pan-y;
}

.drawer-handle {
  width: 40px;
  height: 4px;
  background: #ccc;
  border-radius: 2px;
}

.drawer-content {
  height: calc(100% - 40px);
  overflow: hidden;
  touch-action: auto; /* Allow touch interactions */
}

.drawer-transition-enter-active,
.drawer-transition-leave-active {
  transition: transform 0.3s ease;
}

.drawer-transition-enter-from,
.drawer-transition-leave-to {
  transform: translateY(100%);
}

.drawer-transition-enter-to,
.drawer-transition-leave-from {
  transform: translateY(0);
}

.close-button {
  position: absolute;
  right: 16px;
  padding: 0;
  background: none;
  border: none;
  font-size: 1.5em;
  cursor: pointer;
}
</style>