<template>
  <video ref="video" autoplay muted playsinline></video>
</template>

<script>
export default {
  name: 'CameraView',
  methods: {
    async setupCamera() {
      const video = this.$refs.video;
      try {
        const permissionStatus = await navigator.permissions.query({ name: 'camera' });

        if (permissionStatus.state === 'granted') {
          const stream = await navigator.mediaDevices.getUserMedia({ video: { facingMode: 'environment' } });
          video.srcObject = stream;
          this.stream = stream;
          console.log("Camera stream started");
        } else if (permissionStatus.state === 'prompt') {
          const stream = await navigator.mediaDevices.getUserMedia({ video: { facingMode: 'environment' } });
          video.srcObject = stream;
          this.stream = stream;
          console.log("Camera stream started after prompt");
        } else {
          alert('Camera access has been denied. Please allow camera access in your browser settings.');
          return;
        }

        permissionStatus.onchange = async () => {
          if (permissionStatus.state === 'granted') {
            const stream = await navigator.mediaDevices.getUserMedia({ video: { facingMode: 'environment' } });
            video.srcObject = stream;
            this.stream = stream;
            console.log("Camera stream started after permission change");
            this.analytics.track('Permissions Accepted');
          }
          else if (permissionStatus.state === 'denied') {
            alert('Camera access has been denied. Please allow camera access in your browser settings.');
            this.analytics.track('Permissions Denied');
          }
        };

        video.addEventListener('pause', () => {
          if (document.visibilityState === 'visible') {
              console.log("Resuming playback");
              video.play();
          } 
        });
      } catch (error) {
        console.error('Error accessing camera:', error);
        alert('Error accessing camera. Please allow camera access in your browser settings.');
      }
    },
    printStatus(video) {
      console.log('== Video event: pause');
          console.log("----video.paused: " + video.paused);
          console.log("----video.readyState: " + video.readyState);
          console.log("----video.networkState: " + video.networkState);
          console.log("----video.paused: " + video.paused);
          console.log("----video.currentTime: " + video.currentTime);
          console.log("----video.duration: " + video.duration);
          console.log("----video.volume: " + video.volume);
          console.log("----video.muted: " + video.muted);
          console.log("----video.defaultMuted: " + video.defaultMuted);
          console.log("----video.playbackRate: " + video.playbackRate);
          console.log("----video.played: " + video.played);
          console.log("----video.seekable: " + video.seekable);
          console.log("----video.ended: " + video.ended);
          console.log("----video.autoplay: " + video.autoplay);
          console.log("----video.loop: " + video.loop);
          console.log("----video.controls: " + video.controls);
          console.log("----video.error: " + video.error);
            console.log("----Stream tracks:");
            console.log(this.stream.getTracks());

            console.log("------Track status:");
            this.stream.getTracks().forEach(track => {
            console.log(`-------Track ${track.id}: ${track.readyState}`);
            });
          console.log("----document.visibilityState: " + document.visibilityState);
          console.log("----document.hidden: " + document.hidden);
          console.log("----document.hasFocus(): " + document.hasFocus());
          console.log("----document.readyState: " + document.readyState);
          console.log("----document.visibilityState: " + document.visibilityState);
          console.log("----document.hidden: " + document.hidden);
          console.log("----document.hasFocus(): " + document.hasFocus());
          console.log("----window.document.readyState: " + window.document.readyState);
          console.log("----window.document.visibilityState: " + window.document.visibilityState);
          console.log("----window.document.hidden: " + window.document.hidden);
          console.log("----window.document.hasFocus(): " + window.document.hasFocus());
          console.log("----window.document.activeElement: " + window.document.activeElement);
          console.log("----window.document.hasFocus(): " + window.document.hasFocus());
    },
    beforeDestroy() {
      if (this.stream) {
        console.log("Stopping video stream on component destroy");
        this.stream.getTracks().forEach(track => track.stop());
      }
    },
  },
  mounted() {
    this.setupCamera();
  }
};
</script>

<style scoped>
video {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover; /* This ensures the video covers the entire area without distortion */
}
</style>