import { openDB } from 'idb';

const DB_NAME = 'oneshot-db';
const DB_VERSION = 1; // Incremented version number
const OBJECT_STORE_NAME = 'objects';
const BLOB_STORE_NAME = 'blobs';

const dbPromise = openDB(DB_NAME, DB_VERSION, {
  upgrade(db) {
    if (!db.objectStoreNames.contains(OBJECT_STORE_NAME)) {
      db.createObjectStore(OBJECT_STORE_NAME);
    }
    if (!db.objectStoreNames.contains(BLOB_STORE_NAME)) {
      db.createObjectStore(BLOB_STORE_NAME);
    }
  },
});

export async function getObjects() {
  console.log('Fetching all objects from IndexedDB...');
  const objects = await (await dbPromise).getAll(OBJECT_STORE_NAME);
  console.log('Objects fetched:', objects);
  return objects;
}

export async function getObject(key) {
  console.log(`Fetching object with key ${key} from IndexedDB...`);
  return (await dbPromise).get(OBJECT_STORE_NAME, key);
}

export async function getBlob(id) {
  console.log(`Fetching blob with id ${id} from IndexedDB...`);
  return (await dbPromise).get(BLOB_STORE_NAME, id);
}

export async function addObject(object) {
  const key = object.id;
  console.log('Adding object to IndexedDB:', object);
  return (await dbPromise).add(OBJECT_STORE_NAME, object, key);
}

export async function updateObject(object, key) {
  console.log('Updating object in IndexedDB:', object);
  const updatedObject = { ...object };
  delete updatedObject.localUrl;
  return (await dbPromise).put(OBJECT_STORE_NAME, updatedObject, key);
}

export async function addBlob(blob, key) {
  console.log(`Adding blob with key ${key} to IndexedDB...`);
  return (await dbPromise).put(BLOB_STORE_NAME, blob, key);
}

export async function updateBlob(blob, key) {
  console.log(`Updating blob with key ${key} in IndexedDB...`);
  return (await dbPromise).put(BLOB_STORE_NAME, blob, key);
}

export async function deleteObject(key) {
  console.log('Deleting object from IndexedDB:', key);
  await (await dbPromise).delete(OBJECT_STORE_NAME, key);
  await (await dbPromise).delete(BLOB_STORE_NAME, key);
}

export async function ensureDB() {
  await dbPromise;
}