import { createRouter, createWebHistory } from 'vue-router';
import MainView from '../views/MainView.vue';
import PublicObjectViewer from '@/views/PublicObjectViewer.vue';

const routes = [
  {
    path: '/',
    name: 'Oneshot',
    component: MainView
  }, 
  {
    path: '/public/:modelId',
    name: 'Oneshot - Shared 3D model',
    component: PublicObjectViewer,
    props: true
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
