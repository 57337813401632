<template>
  <div class="info-view">
    <div class="info-content">
      <div class="top-bar">
        <span class="title"><img src="@/assets/logotype/4.png" alt="Oneshot Logo" class="logo" /></span>
        <button class="close-button" @click="$emit('close')">✖️</button>
      </div>
      <div class="info-text">
        <p class="intro">
          How to capture:
        </p>
        <p class="steps">
          1. 📸 Point and shoot <b>just once</b>. No going around required.<br>
        </p>
        <p class="disclaimer">
          <em>For any bugs, requests or questions, reach out to me at <a href="mailto:c@nuvo3d.com">c@nuvo3d.com</a>.</em>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InfoView',
  mounted() {
    this.analytics.track('Info View Opened');
  },
};
</script>

<style scoped>
.info-view {
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1001;
}

.info-content {
  background: white;
  padding: 10px;
  border-radius: 20px;
  max-width: 600px;
  width: 90svw;
  min-height: 300px;
  max-height: 80svh;
  overflow-y: auto;
  position: relative;
  display: flex;
  flex-direction: column;
}

.top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #eee;
  background: white;
}

.title {
  flex: 1;
  text-align: center;
  font-size: 1.2em;
  font-weight: bold;
}

.close-button {
  background: none; /* Remove background */
  border: none; /* Remove border */
  font-size: 1.5em; /* Adjust font size for emoji */
  padding: 0px;
  cursor: pointer;
}

.info-text {
  font-family: 'Montserrat', sans-serif;
  font-size: 1em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  color: #333;
}

.intro {
  align-self: flex-start;
}

.steps {
  text-align: center;
  align-self: center;
}


.disclaimer {
  font-size: 0.9em;
  color: #888;
  align-self: flex-end;
  margin-top: 20px;
  /* style="font-size: 0.9em; color: #888; position: absolute; bottom: 10px;" */
}

.logo {
  display: block;
  margin: 0 auto 0px auto;
  width: 150px;
  height: auto;
}
</style>
