<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App'
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

/* Prevent default long-press behavior and context menu across all browsers */
* {
  -webkit-touch-callout: none; /* Disable callouts in iOS Safari */
  -webkit-user-select: none; /* Disable text selection in Safari */
  -moz-user-select: none; /* Disable text selection in Firefox */
  -ms-user-select: none; /* Disable text selection in IE10+ */
  user-select: none; /* Disable text selection in all browsers */
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); /* Remove tap highlight color in Safari */
  touch-action: manipulation; /* Prevent default actions triggered by touch events */
}

body {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background: black;
  margin: 0;
  background-color: white;
}
</style>
